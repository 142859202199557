import React, { useEffect, useContext } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PitchDeckList from "../components/pitchDecks/pitchDeckList"

import { AppContext } from "../context/appContext"

const IndexPage = () => {
  const { isLoggedIn, clearUser } = useContext(AppContext)
  useEffect(() => {
    if (isLoggedIn) {
      clearUser()
    }
  }, [isLoggedIn, clearUser])
  return (
    <Layout>
      <SEO title="Home" />
      <PitchDeckList />
    </Layout>
  )
}

export default IndexPage
